import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon, UnfoldMore as OrderIcon, FileCopy as CopyIcon } from '@material-ui/icons';

// Services
import { getMemberships, updateIndexPreferences, getIndexPreferences } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import {subscriptionStatuses, transferStatuses, billStates, billStatuses} from 'services/constants';
import { getLabelStatus, getColorStatus, getFormattedDate, getMonthYearDate, getFormattedCreatedAt, getFilterOrder } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Tooltip from 'components/tooltip';
import SelectCreatable from 'components/select-creatable';
import Select from 'components/react-select';

// Views
import Bill from '../../common/bill';
import UpdateMembership from './update-membership';
import MembershipsExport from './memberships-export';
import BiodyvinImport from './biodyvin-import';
import GroupFilter from 'views/common/group-filter';

// Selects
import SelectAdherentType from 'selects/select-adherent-type';
import SelectMembershipTypes from 'selects/select-membership-types';
import SelectYesOrNo from 'selects/select-yes-or-no';
import SelectActivities from 'selects/select-activities';
import SelectTransferStatuses from 'selects/select-transfer-statuses';
import SelectBillStatuses from 'selects/select-bill-statuses';

// Columns
import { ClientCodeHeader, ClientCodeFilter, ClientCodeValue } from 'views/columns/client-code';
import { ExternalClientCodeHeader, ExternalClientCodeFilter, ExternalClientCodeValue } from 'views/columns/external-client-code';
import { LastNameHeader, LastNameFilter, LastNameValue } from 'views/columns/last-name';
import { FirstNameHeader, FirstNameFilter, FirstNameValue } from 'views/columns/first-name';
import { CompanyNameHeader, CompanyNameFilter, CompanyNameValue } from 'views/columns/company-name';
import { MobilePhoneHeader, MobilePhoneFilter, MobilePhoneValue } from 'views/columns/mobile-phone';
import { FrenchDepartmentHeader, FrenchDepartmentFilter, FrenchDepartmentValue } from 'views/columns/french-department';
import { CityHeader, CityFilter, CityValue } from 'views/columns/city';
import { AddressHeader, AddressFilter, AddressValue } from 'views/columns/address';
import { AddressDetailsHeader, AddressDetailsFilter, AddressDetailsValue } from 'views/columns/address-details';
import { CountryHeader, CountryFilter, CountryValue } from 'views/columns/country';
import { PhoneHeader, PhoneFilter, PhoneValue } from 'views/columns/phone';
import { OtherPhoneHeader, OtherPhoneFilter, OtherPhoneValue } from 'views/columns/other-phone';
import { EmailHeader, EmailFilter, EmailValue } from 'views/columns/email';
import { UserCategoryHeader, UserCategoryFilter, UserCategoryValue } from 'views/columns/user-category';
import { BirthDateHeader, BirthDateFilter, BirthDateValue } from 'views/columns/birth-date';
import { FederatedAssociationHeader, FederatedAssociationFilter, FederatedAssociationValue } from 'views/columns/federated-association';
import { BillStateHeader, BillStateFilter, BillStateValue } from 'views/columns/bill-state';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- MEMBERSHIPS ROUTER ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MembershipsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Memberships />
            </Route>
            <Route path={`${match.path}/:billId`}>
                <Bill />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- MEMBERSHIPS ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Memberships = () => {
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;
    const storedFilters = JSON.parse(sessionStorage.getItem('memberships-filters'));

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        memberships: [],
        offset: 0,
        limit: 30,
        total_count: 0,

        // Links
        openLinks: false,
        billId: null,

        // Update
        openUpdate: false,
        membership: null,

        // Biodyvin
        openBiodyvinImport: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_membership_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_adherent_type: true,
        enabled_membership_type: true,
        enabled_last_name: true,
        enabled_first_name: true,
        enabled_company_name: true,
        enabled_mobile_phone: true,
        enabled_membership_email: false,
        enabled_french_department: true,
        enabled_city: true,
        enabled_year_membership_expiration: true,
        enabled_membership_expiration: false,
        enabled_address: false,
        enabled_address_details: false,
        enabled_country: false,
        enabled_phone: false,
        enabled_other_phone: false,
        enabled_email: false,
        enabled_subscription_expiration: false,
        enabled_month_year_subscription_expiration: false,
        enabled_user_category: false,
        enabled_birth_date: false,
        enabled_federated_association: false,
        enabled_subscription_email: false,
        enabled_bill_email: false,
        enabled_is_biodyvin: false,
        enabled_is_demeter: false,
        enabled_main_activity: false,
        enabled_secondary_activity: false,
        enabled_postal_code: false,
        enabled_transfer_status: false,
        enabled_created_at: false,
        enabled_bill_state: false,
        enabled_bill_reference: false,
        enabled_bill_status: false,
        enabled_renew_status: false,

        // Filters
        order_by: storedFilters?.order_by || '',
        order: storedFilters?.order || 'desc',
        membership_statuses: storedFilters?.membership_statuses || [],
        client_code: storedFilters?.client_code || '',
        external_client_code: storedFilters?.external_client_code || '',
        adherent_type: storedFilters?.adherent_type != null ? storedFilters?.adherent_type : -1,
        membership_types: storedFilters?.membership_types || [],
        last_name: storedFilters?.last_name || '',
        first_name: storedFilters?.first_name || '',
        company_name: storedFilters?.company_name || '',
        mobile_phone: storedFilters?.mobile_phone || '',
        membership_email: '',
        french_departments: storedFilters?.french_departments || [],
        city: storedFilters?.city || '',
        year_membership_expirations: storedFilters?.year_membership_expirations || [],
        membership_expiration: storedFilters?.membership_expiration || '',
        address: storedFilters?.address || '',
        address_details: storedFilters?.address_details || '',
        countries: storedFilters?.countries || [],
        phone: storedFilters?.phone || '',
        other_phone: storedFilters?.other_phone || '',
        email: storedFilters?.email || '',
        subscription_expiration: storedFilters?.subscription_expiration || '',
        month_year_subscription_expirations: storedFilters?.month_year_subscription_expirations || [],
        user_categories: storedFilters?.user_categories || [],
        birth_date: storedFilters?.birth_date || '',
        federated_associations: storedFilters?.federated_associations || [],
        subscription_email: storedFilters?.subscription_email || '',
        bill_email: storedFilters?.bill_email || '',
        is_biodyvin: storedFilters?.is_biodyvin != null ? storedFilters?.is_biodyvin : -1,
        is_demeter: storedFilters?.is_demeter != null ? storedFilters?.is_demeter : -1,
        main_activities: storedFilters?.main_activities || [],
        secondary_activities: storedFilters?.secondary_activities || [],
        postal_codes: storedFilters?.postal_codes || [],
        transfer_statuses: storedFilters?.transfer_statuses || [],
        created_at: storedFilters?.created_at || '',
        bill_state: storedFilters?.bill_state != null ? storedFilters?.bill_state : -1,
        bill_reference: storedFilters?.bill_reference || '',
        bill_statuses: storedFilters?.bill_statuses || [],
        user_list_ids: storedFilters?.user_list_ids || [],
        renew_status: storedFilters?.renew_status || null,
        copy_emails: false,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        sessionStorage.removeItem('memberships-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            membership_statuses: [],
            order_by: '',
            order: 'desc',
            client_code: '',
            external_client_code: '',
            adherent_type: -1,
            membership_types: [],
            last_name: '',
            first_name: '',
            company_name: '',
            mobile_phone: '',
            membership_email: '',
            french_departments: [],
            city: '',
            year_membership_expirations: [],
            membership_expiration: '',
            address: '',
            address_details: '',
            countries: [],
            phone: '',
            other_phone: '',
            email: '',
            subscription_expiration: '',
            month_year_subscription_expirations: [],
            user_categories: [],
            birth_date: '',
            federated_associations: [],
            subscription_email: '',
            bill_email: '',
            is_biodyvin: -1,
            is_demeter: -1,
            main_activities: [],
            secondary_activities: [],
            postal_codes: [],
            transfer_statuses: [],
            created_at: '',
            bill_state: -1,
            bill_reference: '',
            bill_statuses: [],
            user_list_ids: [],
            renew_status: null,
            copy_emails: false,
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'memberships',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false,
                            loading: true,
                            enabled_membership_status: columns.enabled_membership_status,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_adherent_type: columns.enabled_adherent_type,
                            enabled_membership_type: columns.enabled_membership_type,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_first_name: columns.enabled_first_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_membership_email: false,
                            enabled_french_department: columns.enabled_french_department,
                            enabled_city: columns.enabled_city,
                            enabled_year_membership_expiration: columns.enabled_year_membership_expiration,
                            enabled_membership_expiration: columns.enabled_membership_expiration,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_country: columns.enabled_country,
                            enabled_phone: columns.enabled_phone,
                            enabled_other_phone: columns.enabled_other_phone,
                            enabled_email: columns.enabled_email,
                            enabled_subscription_expiration: columns.enabled_subscription_expiration,
                            enabled_month_year_subscription_expiration: columns.enabled_month_year_subscription_expiration,
                            enabled_user_category: columns.enabled_user_category,
                            enabled_birth_date: columns.enabled_birth_date,
                            enabled_federated_association: columns.enabled_federated_association,
                            enabled_subscription_email: columns.enabled_subscription_email,
                            enabled_bill_email: columns.enabled_bill_email,
                            enabled_is_biodyvin: columns.enabled_is_biodyvin,
                            enabled_is_demeter: columns.enabled_is_demeter,
                            enabled_main_activity: columns.enabled_main_activity,
                            enabled_secondary_activity: columns.enabled_secondary_activity,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_transfer_status: columns.enabled_transfer_status,
                            enabled_created_at: columns.enabled_created_at,
                            enabled_bill_state: columns.enabled_bill_state,
                            enabled_bill_reference: columns.enabled_bill_reference,
                            enabled_bill_status: columns.enabled_bill_status,
                            enabled_renew_status: columns.enabled_renew_status,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('memberships-filters', JSON.stringify({
                order_by: state.order_by,
                order: state.order,
                membership_statuses: state.membership_statuses,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                adherent_type: state.adherent_type,
                membership_types: state.membership_types,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                membership_email: state.membership_email,
                french_departments: state.french_departments,
                city: state.city,
                year_membership_expirations: state.year_membership_expirations,
                membership_expiration: state.membership_expiration,
                address: state.address,
                address_details: state.address_details,
                countries: state.countries,
                phone: state.phone,
                other_phone: state.other_phone,
                email: state.email,
                subscription_expiration: state.subscription_expiration,
                month_year_subscription_expirations: state.month_year_subscription_expirations,
                user_categories: state.user_categories,
                birth_date: state.birth_date,
                federated_associations: state.federated_associations,
                subscription_email: state.subscription_email,
                bill_email: state.bill_email,
                is_biodyvin: state.is_biodyvin,
                is_demeter: state.is_demeter,
                main_activities: state.main_activities,
                secondary_activities: state.secondary_activities,
                postal_codes: state.postal_codes,
                transfer_statuses: state.transfer_statuses,
                created_at: state.created_at,
                bill_state: state.bill_state,
                bill_reference: state.bill_reference,
                bill_statuses: state.bill_statuses,
                user_list_ids: state.user_list_ids,
                renew_status: state.renew_status,
            }));

            getMemberships({
                offset: state.offset,
                limit: state.limit,
                membership_statuses: state.membership_statuses ? state.membership_statuses.map(s => s.value) : null,
                order_by: state.order_by,
                order: state.order,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                adherent_type: state.adherent_type,
                membership_type_ids: state.membership_types ? state.membership_types.map(s => s.value) : null,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                membership_email: state.membership_email,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                cities: state.cities ? state.cities.map(c => c.value) : null,
                year_membership_expirations: state.year_membership_expirations ? state.year_membership_expirations.map(e => e.value) : null,
                membership_expiration: state.membership_expiration,
                address: state.address,
                address_details: state.address_details,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                phone: state.phone,
                other_phone: state.other_phone,
                email: state.email,
                subscription_expiration: state.subscription_expiration,
                month_year_subscription_expirations: state.month_year_subscription_expirations ? state.month_year_subscription_expirations.map(e => e.value) : null,
                user_category_ids: state.user_categories ? state.user_categories.map(c => c.value) : null,
                birth_date: state.birth_date,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
                subscription_email: state.subscription_email,
                bill_email: state.bill_email,
                is_biodyvin: state.is_biodyvin,
                is_demeter: state.is_demeter,
                main_activity_ids: state.main_activities ? state.main_activities.map(a => a.value) : null,
                secondary_activity_ids: state.secondary_activities ? state.secondary_activities.map(a => a.value) : null,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                transfer_statuses: state.transfer_statuses ? state.transfer_statuses.map(s => s.value) : null,
                created_at: state.created_at,
                bill_state: state.bill_state,
                bill_reference: state.bill_reference,
                bill_statuses: state.bill_statuses ? state.bill_statuses.map(s => s.value) : null,
                user_list_ids: state.user_list_ids,
                renew_status: state.renew_status ? state.renew_status.value : null,
                copy_emails: state.copy_emails,
            }).then(res => {
                if (res.status === 200) {

                    if (state.copy_emails) {

                        navigator.clipboard.writeText(res?.data?.data?.emails).then(function() {
                            enqueueSnackbar('Emails copiés avec succès !', { variant: 'success' });
                        }, function(err) {
                            enqueueSnackbar('Copie des emails échoués', { variant: 'error' });
                        });

                        setState({
                            ...state,
                            loading: false,
                            copy_emails: false,
                        });
                    }
                    else {
                        setState({
                            ...state,
                            loading: false,
                            offset: res.data.data.offset,
                            limit: res.data.data.limit,
                            total_count: res.data.data.totalCount,
                            memberships: res.data.data.memberships,
                        });
                    }
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push('/management')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Adhésions
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Button
                        onClick={() => setState({ ...state, openBiodyvinImport: true })}
                        variant="contained" color="primary"
                    >
                        Import Biodyvin
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div style={{ display: 'flex' }}>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Copier les emails"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, loading: true, copy_emails: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <CopyIcon />
                            </Button>
                        )}
                    />
                    <GroupFilter
                        userListIds={state.user_list_ids}
                        setUserListIds={user_list_ids => onSelectSearch('user_list_ids', user_list_ids)}
                    />
                    <MembershipsExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                            setState={setState}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <MembershipList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- LINKS -------------------- */}
            {state.openLinks && (
                <MembershipLinks
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- BIODYVIN IMPORT -------------------- */}
            {state.openBiodyvinImport && (
                <BiodyvinImport
                    onClose={() => setState({ ...state, openBiodyvinImport: false })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state, setState }) => {

    function onSearch(order_by) {
        setState({ ...state, loading: true, error: false, offset: 0, order_by, order: getFilterOrder(state.order) });
    }

    return (
        <div className="list-headers">
            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list" style={{ width: 180 }}>
                    Statut adhésion
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <ClientCodeHeader />
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <ExternalClientCodeHeader />
            )}
            {/* -------------------- ADHERENT TYPE -------------------- */}
            {state.enabled_adherent_type && (
                <div className="column-list">
                    Type d'adhérent
                </div>
            )}
            {/* -------------------- MEMBERSHIP TYPE -------------------- */}
            {state.enabled_membership_type && (
                <div className="column-list">
                    Type d'adhésion
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <LastNameHeader />
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <FirstNameHeader />
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <CompanyNameHeader />
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <MobilePhoneHeader />
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    Email adhésion
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <FrenchDepartmentHeader />
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <CityHeader />
            )}
            {/* -------------------- YEAR MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_year_membership_expiration && (
                <div className="column-list">
                    Année de validité adhé.
                </div>
            )}
            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_membership_expiration && (
                <div className="column-list">
                    Date d'expiration adhé.
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <AddressHeader
                    label="Adresse ADH (n° et voie)"
                />
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <AddressDetailsHeader
                    label="Adresse ADH (complément)"
                />
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <CountryHeader />
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <PhoneHeader />
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <OtherPhoneHeader />
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <EmailHeader />
            )}
            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_subscription_expiration && (
                <div className="column-list">
                    Date d'expiration abo.
                </div>
            )}
            {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_month_year_subscription_expiration && (
                <div className="column-list">
                    Mois / Année d'expiration abo.
                </div>
            )}
            {/* -------------------- USER CATEGORY -------------------- */}
            {state.enabled_user_category && (
                <UserCategoryHeader />
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <BirthDateHeader />
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <FederatedAssociationHeader />
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    Email abonnement
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    Email bon de commande
                </div>
            )}
            {/* -------------------- IS BIODYVIN -------------------- */}
            {state.enabled_is_biodyvin && (
                <div className="column-list">
                    Adhérent Biodyvin
                </div>
            )}
            {/* -------------------- IS DEMETER -------------------- */}
            {state.enabled_is_demeter && (
                <div className="column-list">
                    Adhérent Demeter
                </div>
            )}
            {/* -------------------- MAIN ACTIVITY -------------------- */}
            {state.enabled_main_activity && (
                <div className="column-list">
                    Activité principale
                </div>
            )}
            {/* -------------------- SECONDARY ACTIVITY -------------------- */}
            {state.enabled_secondary_activity && (
                <div className="column-list">
                    Activité secondaire
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- TRANSFER STATUS -------------------- */}
            {state.enabled_transfer_status && (
                <div className="column-list">
                    Statut du transfert
                </div>
            )}
            {/* -------------------- CREATED AT -------------------- */}
            {state.enabled_created_at && (
                <div className="column-list">
                    <Button
                        onClick={() => onSearch('created_at')}
                        style={{ textTransform: 'none', padding: 0, color: 'white' }}
                    >
                        Date BC
                        <OrderIcon style={{ marginLeft: 5 }} />
                    </Button>
                </div>
            )}
            {/* -------------------- BILL STATE -------------------- */}
            {state.enabled_bill_state && (
                <BillStateHeader />
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list">
                    Référence BC
                </div>
            )}
            {/* -------------------- BILL STATUS -------------------- */}
            {state.enabled_bill_status && (
                <div className="column-list">
                    Statut BC
                </div>
            )}
            {/* -------------------- RENEW STATUS -------------------- */}
            {state.enabled_renew_status && (
                <div className="column-list">
                    Statut renouvellement
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list" style={{ width: 180 }}>
                    <Select
                        placeholder="Statut adhésion"
                        value={state.membership_statuses}
                        onChange={membership_statuses => onSelectSearch('membership_statuses', membership_statuses)}
                        options={subscriptionStatuses.map(s => ({ label: s.label, value: s.value }))}
                        isMulti
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <ClientCodeFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <ExternalClientCodeFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- ADHERENT TYPE -------------------- */}
            {state.enabled_adherent_type && (
                <div className="column-list">
                    <SelectAdherentType
                        adherentType={state.adherent_type}
                        setAdherentType={adherent_type => onSelectSearch('adherent_type', adherent_type)}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP TYPE -------------------- */}
            {state.enabled_membership_type && (
                <div className="column-list">
                    <SelectMembershipTypes
                        membershipTypes={state.membership_types}
                        setMembershipTypes={membership_types => onSelectSearch('membership_types', membership_types)}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <LastNameFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <FirstNameFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <CompanyNameFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <MobilePhoneFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    <InputText
                        label="Email adhésion"
                        value={state.membership_email}
                        onChange={e => setState({ ...state, membership_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <FrenchDepartmentFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <CityFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- YEAR MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_year_membership_expiration && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.year_membership_expirations}
                        onChange={year_membership_expirations => onSelectSearch('year_membership_expirations', year_membership_expirations)}
                        placeholder="Année de validité adhé."
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_membership_expiration && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.membership_expiration}
                        onChange={e => setState({ ...state, membership_expiration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <AddressFilter
                    label="Adresse ADH (n° et voie)"
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <AddressDetailsFilter
                    label="Adresse ADH (complément)"
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <CountryFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <PhoneFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <OtherPhoneFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <EmailFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_subscription_expiration && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.subscription_expiration}
                        onChange={e => setState({ ...state, subscription_expiration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_month_year_subscription_expiration && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.month_year_subscription_expirations}
                        onChange={month_year_subscription_expirations => onSelectSearch('month_year_subscription_expirations', month_year_subscription_expirations)}
                        placeholder="Mois / Année d'expiration abo."
                    />
                </div>
            )}
            {/* -------------------- USER CATEGORY -------------------- */}
            {state.enabled_user_category && (
                <UserCategoryFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <BirthDateFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <FederatedAssociationFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    <InputText
                        label="Email abonnement"
                        value={state.subscription_email}
                        onChange={e => setState({ ...state, subscription_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    <InputText
                        label="Email bon de commande"
                        value={state.bill_email}
                        onChange={e => setState({ ...state, bill_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- IS BIODYVIN -------------------- */}
            {state.enabled_is_biodyvin && (
                <div className="column-list">
                    <SelectYesOrNo
                        label="Adhérent Biodyvin"
                        value={state.is_biodyvin}
                        onChange={is_biodyvin => onSelectSearch('is_biodyvin', is_biodyvin)}
                    />
                </div>
            )}
            {/* -------------------- IS DEMETER -------------------- */}
            {state.enabled_is_demeter && (
                <div className="column-list">
                    <SelectYesOrNo
                        label="Adhérent Demeter"
                        value={state.is_demeter}
                        onChange={is_demeter => onSelectSearch('is_demeter', is_demeter)}
                    />
                </div>
            )}
            {/* -------------------- MAIN ACTIVITY -------------------- */}
            {state.enabled_main_activity && (
                <div className="column-list">
                    <SelectActivities
                        label="Activité principale"
                        activities={state.main_activities}
                        setActivities={main_activities => onSelectSearch('main_activities', main_activities)}
                    />
                </div>
            )}
            {/* -------------------- SECONDARY ACTIVITY -------------------- */}
            {state.enabled_secondary_activity && (
                <div className="column-list">
                    <SelectActivities
                        label="Activité secondaire"
                        activities={state.secondary_activities}
                        setActivities={secondary_activities => onSelectSearch('secondary_activities', secondary_activities)}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                        placeholder="Code postal"
                    />
                </div>
            )}
            {/* -------------------- TRANSFER STATUS -------------------- */}
            {state.enabled_transfer_status && (
                <div className="column-list">
                    <SelectTransferStatuses
                        statuses={state.transfer_statuses}
                        setStatuses={transfer_statuses => onSelectSearch('transfer_statuses', transfer_statuses)}
                    />
                </div>
            )}
            {/* -------------------- CREATED AT -------------------- */}
            {state.enabled_created_at && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.created_at}
                        onChange={e => setState({ ...state, created_at: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL STATE -------------------- */}
            {state.enabled_bill_state && (
                <BillStateFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list">
                    <InputText
                        label="Référence BC"
                        value={state.bill_reference}
                        onChange={e => setState({ ...state, bill_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL STATUS -------------------- */}
            {state.enabled_bill_status && (
                <div className="column-list">
                    <SelectBillStatuses
                        statuses={state.bill_statuses}
                        setStatuses={statuses => onSelectSearch('bill_statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- RENEW STATUS -------------------- */}
            {state.enabled_renew_status && (
                <div className="column-list">
                    <Select
                        placeholder="Statut renouvellement"
                        value={state.renew_status}
                        onChange={renew_status => onSelectSearch('renew_status', renew_status)}
                        options={[{ value: 'renewed', label: 'Renouvelé' }, { value: 'not_renewed', label: 'Non renouvelé' }]}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- MEMBERSHIP LIST --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MembershipList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.memberships.length > 0 && (
                state.memberships.map(membership => (
                    <Fragment key={membership.id}>
                        <div
                            onClick={() => setState({ ...state, openLinks: true, billId: membership.billId, membership })}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                            {state.enabled_membership_status && (
                                <div className="column-list" style={{ width: 180 }}>
                                    <b className="title-status-list" style={{ color: getColorStatus(subscriptionStatuses, membership.status) }}>
                                        {getLabelStatus(subscriptionStatuses, membership.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <ClientCodeValue
                                    value={membership.user?.clientCode}
                                />
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <ExternalClientCodeValue
                                    value={membership.user?.external_client_code}
                                />
                            )}
                            {/* -------------------- ADHERENT TYPE -------------------- */}
                            {state.enabled_adherent_type && (
                                <div className="column-list">
                                    {membership.membershipType && membership.membershipType.is_pro === 0 ? (
                                        'Particulier'
                                    ) : (
                                        'Professionnel'
                                    )}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                            {state.enabled_membership_type && (
                                <div className="column-list">
                                    {membership.membershipType ? membership.membershipType.name : ''}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <LastNameValue
                                    value={membership.user?.last_name}
                                />
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <FirstNameValue
                                    value={membership.user?.first_name}
                                />
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <CompanyNameValue
                                    value={membership.company_name}
                                />
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <MobilePhoneValue
                                    value={membership.user?.mobile_phone}
                                />
                            )}
                            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                            {state.enabled_membership_email && (
                                <div className="column-list">
                                    {membership.user ? membership.user.membershipEmail : ''}
                                </div>
                            )}
                            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                            {state.enabled_french_department && (
                                <FrenchDepartmentValue
                                    value={membership.user?.department && (
                                        membership.user.department.name + ' (' + membership.user.department.number + ')'
                                    )}
                                />
                            )}
                            {/* -------------------- CITY -------------------- */}
                            {state.enabled_city && (
                                <CityValue
                                    value={membership.user?.city}
                                />
                            )}
                            {/* -------------------- YEAR MEMBERSHIP EXPIRATION -------------------- */}
                            {state.enabled_year_membership_expiration && (
                                <div className="column-list">
                                    {membership.end_date ? membership.end_date.split('-')[0] : ''}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
                            {state.enabled_membership_expiration && (
                                <div className="column-list">
                                    {membership.end_date ? getFormattedDate(membership.end_date) : ''}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <AddressValue
                                    value={membership.user?.address}
                                />
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <AddressDetailsValue
                                    value={membership.user?.address_details}
                                />
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <CountryValue
                                    value={membership.user?.country?.name}
                                />
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <PhoneValue
                                    value={membership.user?.phone}
                                />
                            )}
                            {/* -------------------- OTHER PHONE -------------------- */}
                            {state.enabled_other_phone && (
                                <OtherPhoneValue
                                    value={membership.user?.other_phone}
                                />
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <EmailValue
                                    value={membership.user?.email}
                                />
                            )}
                            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
                            {state.enabled_subscription_expiration && (
                                <div className="column-list">
                                    {(membership.user && membership.user.subscriptionExpiration) ? getFormattedDate(membership.user.subscriptionExpiration) : ''}
                                </div>
                            )}
                            {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
                            {state.enabled_month_year_subscription_expiration && (
                                <div className="column-list">
                                    {(membership.user && membership.user.subscriptionExpiration) ? getMonthYearDate(membership.user.subscriptionExpiration) : ''}
                                </div>
                            )}
                            {/* -------------------- USER CATEGORY -------------------- */}
                            {state.enabled_user_category && (
                                <UserCategoryValue
                                    value={membership.user?.userCategories?.map((category, index) => {
                                        if (index === 0) return category.name;
                                        return ', ' + category.name;
                                    })}
                                />
                            )}
                            {/* -------------------- BIRTH DATE -------------------- */}
                            {state.enabled_birth_date && (
                                <BirthDateValue
                                    value={getFormattedDate(membership.user?.birth_date)}
                                />
                            )}
                            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <FederatedAssociationValue
                                    value={membership.user?.federatedAssociation?.name}
                                />
                            )}
                            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                            {state.enabled_subscription_email && (
                                <div className="column-list">
                                    {membership.user ? membership.user.subscriptionEmail : ''}
                                </div>
                            )}
                            {/* -------------------- BILL EMAIL -------------------- */}
                            {state.enabled_bill_email && (
                                <div className="column-list">
                                    {membership.user ? membership.user.billEmail : ''}
                                </div>
                            )}
                            {/* -------------------- IS BIODYVIN -------------------- */}
                            {state.enabled_is_biodyvin && (
                                <div className="column-list">
                                    {membership.is_biodyvin === 0 ? 'Non' : 'Oui'}
                                </div>
                            )}
                            {/* -------------------- IS DEMETER -------------------- */}
                            {state.enabled_is_demeter && (
                                <div className="column-list">
                                    {membership.is_demeter === 0 ? 'Non' : 'Oui'}
                                </div>
                            )}
                            {/* -------------------- MAIN ACTIVITY -------------------- */}
                            {state.enabled_main_activity && (
                                <div className="column-list">
                                    {membership.mainActivity ? membership.mainActivity.name : ''}
                                </div>
                            )}
                            {/* -------------------- SECONDARY ACTIVITY -------------------- */}
                            {state.enabled_secondary_activity && (
                                <div className="column-list">
                                    {membership.secondaryActivities.map((activity, index) => {
                                        if (index === 0) return activity.name;
                                        else return ', ' + activity.name;
                                    })}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {membership.user?.postal_code}
                                </div>
                            )}
                            {/* -------------------- TRANSFER STATUS -------------------- */}
                            {state.enabled_transfer_status && (
                                <div className="column-list">
                                    {getLabelStatus(transferStatuses, membership.transfer_status)}
                                </div>
                            )}
                            {/* -------------------- CREATED AT -------------------- */}
                            {state.enabled_created_at && (
                                <div className="column-list">
                                    {getFormattedCreatedAt(membership.created_at)}
                                </div>
                            )}
                            {/* -------------------- BILL STATE -------------------- */}
                            {state.enabled_bill_state && (
                                <BillStateValue
                                    value={getLabelStatus(billStates, membership.bill_state)}
                                />
                            )}
                            {/* -------------------- BILL REFERENCE -------------------- */}
                            {state.enabled_bill_reference && (
                                <div className="column-list">
                                    {membership.bill_reference}
                                </div>
                            )}
                            {/* -------------------- BILL STATUS -------------------- */}
                            {state.enabled_bill_status && (
                                <div className="column-list">
                                    <b style={{ color: getColorStatus(billStatuses, membership.bill_status) }}>
                                        {getLabelStatus(billStatuses, membership.bill_status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- RENEW STATUS -------------------- */}
                            {state.enabled_renew_status && (
                                <div className="column-list">
                                    {membership.renewStatus === 'renewed' ? 'Renouvelé' : 'Non renouvelé'}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateMembership
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    membership={state.membership}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_membership_status: state.enabled_membership_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_adherent_type: state.enabled_adherent_type,
            enabled_membership_type: state.enabled_membership_type,
            enabled_last_name: state.enabled_last_name,
            enabled_first_name: state.enabled_first_name,
            enabled_company_name: state.enabled_company_name,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_membership_email: state.enabled_membership_email,
            enabled_french_department: state.enabled_french_department,
            enabled_city: state.enabled_city,
            enabled_year_membership_expiration: state.enabled_year_membership_expiration,
            enabled_membership_expiration: state.enabled_membership_expiration,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_country: state.enabled_country,
            enabled_phone: state.enabled_phone,
            enabled_other_phone: state.enabled_other_phone,
            enabled_email: state.enabled_email,
            enabled_subscription_expiration: state.enabled_subscription_expiration,
            enabled_month_year_subscription_expiration: state.enabled_month_year_subscription_expiration,
            enabled_user_category: state.enabled_user_category,
            enabled_birth_date: state.enabled_birth_date,
            enabled_federated_association: state.enabled_federated_association,
            enabled_subscription_email: state.enabled_subscription_email,
            enabled_bill_email: state.enabled_bill_email,
            enabled_is_biodyvin: state.enabled_is_biodyvin,
            enabled_is_demeter: state.enabled_is_demeter,
            enabled_main_activity: state.enabled_main_activity,
            enabled_secondary_activity: state.enabled_secondary_activity,
            enabled_postal_code: state.enabled_postal_code,
            enabled_transfer_status: state.enabled_transfer_status,
            enabled_created_at: state.enabled_created_at,
            enabled_bill_state: state.enabled_bill_state,
            enabled_bill_reference: state.enabled_bill_reference,
            enabled_bill_status: state.enabled_bill_status,
            enabled_renew_status: state.enabled_renew_status,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'memberships',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            {state.loadingColumns ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Gérer les colonnes affichées
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div>
                                {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_membership_status}
                                        onChange={e => setState({ ...state, enabled_membership_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut adhésion
                                </Grid>
                                {/* -------------------- CLIENT CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_client_code}
                                        onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code client
                                </Grid>
                                {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_external_client_code}
                                        onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code client externe
                                </Grid>
                                {/* -------------------- ADHERENT TYPE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_adherent_type}
                                        onChange={e => setState({ ...state, enabled_adherent_type: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Type d'adhérent
                                </Grid>
                                {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_membership_type}
                                        onChange={e => setState({ ...state, enabled_membership_type: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Type d'adhésion
                                </Grid>
                                {/* -------------------- LAST NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_last_name}
                                        onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom
                                </Grid>
                                {/* -------------------- FIRST NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_first_name}
                                        onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Prénom
                                </Grid>
                                {/* -------------------- COMPANY NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_company_name}
                                        onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom de l'entreprise
                                </Grid>
                                {/* -------------------- MOBILE PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_mobile_phone}
                                        onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Téléphone portable
                                </Grid>
                                {/* -------------------- EMAIL -------------------- */}
                                {/*<Grid container alignItems="center">*/}
                                {/*    <Checkbox*/}
                                {/*        checked={state.enabled_membership_email}*/}
                                {/*        onChange={e => setState({ ...state, enabled_membership_email: e.target.checked })}*/}
                                {/*        style={{ padding: 6 }}*/}
                                {/*    />*/}
                                {/*    Email adhésion*/}
                                {/*</Grid>*/}
                                {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_french_department}
                                        onChange={e => setState({ ...state, enabled_french_department: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Département
                                </Grid>
                                {/* -------------------- CITY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_city}
                                        onChange={e => setState({ ...state, enabled_city: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Ville
                                </Grid>
                                {/* -------------------- YEAR MEMBERSHIP EXPIRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_year_membership_expiration}
                                        onChange={e => setState({ ...state, enabled_year_membership_expiration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Année de validité adhé.
                                </Grid>
                                {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_membership_expiration}
                                        onChange={e => setState({ ...state, enabled_membership_expiration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date d'expiration
                                </Grid>
                                {/* -------------------- ADDRESS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_address}
                                        onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Adresse ADH (n° et voie)
                                </Grid>
                                {/* -------------------- ADDRESS DETAILS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_address_details}
                                        onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Adresse ADH (complément)
                                </Grid>
                                {/* -------------------- COUNTRY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_country}
                                        onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Pays
                                </Grid>
                                {/* -------------------- PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_phone}
                                        onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Téléphone fixe
                                </Grid>
                                {/* -------------------- OTHER PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_other_phone}
                                        onChange={e => setState({ ...state, enabled_other_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Autre téléphone
                                </Grid>
                                {/* -------------------- EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_email}
                                        onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email compte
                                </Grid>
                            </div>
                            <div>
                                {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_subscription_expiration}
                                        onChange={e => setState({ ...state, enabled_subscription_expiration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date d'expiration abo.
                                </Grid>
                                {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_month_year_subscription_expiration}
                                        onChange={e => setState({ ...state, enabled_month_year_subscription_expiration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Mois / Année d'expiration abo.
                                </Grid>
                                {/* -------------------- USER CATEGORY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_user_category}
                                        onChange={e => setState({ ...state, enabled_user_category: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Rôle
                                </Grid>
                                {/* -------------------- BIRTH DATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_birth_date}
                                        onChange={e => setState({ ...state, enabled_birth_date: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date de naissance
                                </Grid>
                                {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_federated_association}
                                        onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Association fédérée
                                </Grid>
                                {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_subscription_email}
                                        onChange={e => setState({ ...state, enabled_subscription_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email abonnement
                                </Grid>
                                {/* -------------------- BILL EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_email}
                                        onChange={e => setState({ ...state, enabled_bill_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email bon de commande
                                </Grid>
                                {/* -------------------- IS BIODYVIN -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_is_biodyvin}
                                        onChange={e => setState({ ...state, enabled_is_biodyvin: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Adhérent Biodyvin
                                </Grid>
                                {/* -------------------- IS DEMETER -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_is_demeter}
                                        onChange={e => setState({ ...state, enabled_is_demeter: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Adhérent Demeter
                                </Grid>
                                {/* -------------------- MAIN ACTIVITY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_main_activity}
                                        onChange={e => setState({ ...state, enabled_main_activity: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Activité principale
                                </Grid>
                                {/* -------------------- SECONDARY ACTIVITY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_secondary_activity}
                                        onChange={e => setState({ ...state, enabled_secondary_activity: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Activité secondaire
                                </Grid>
                                {/* -------------------- POSTAL CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_postal_code}
                                        onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code postal
                                </Grid>
                                {/* -------------------- TRANSFER STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_transfer_status}
                                        onChange={e => setState({ ...state, enabled_transfer_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut du transfert
                                </Grid>
                                {/* -------------------- CREATED AT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_created_at}
                                        onChange={e => setState({ ...state, enabled_created_at: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date BC
                                </Grid>
                                {/* -------------------- BILL STATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_state}
                                        onChange={e => setState({ ...state, enabled_bill_state: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Etat BC
                                </Grid>
                                {/* -------------------- BILL REFERENCE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_reference}
                                        onChange={e => setState({ ...state, enabled_bill_reference: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Référence BC
                                </Grid>
                                {/* -------------------- BILL STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_status}
                                        onChange={e => setState({ ...state, enabled_bill_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut BC
                                </Grid>
                                {/* -------------------- RENEW STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_renew_status}
                                        onChange={e => setState({ ...state, enabled_renew_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut renouvellement
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                            Fermer
                        </Button>
                        <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- MEMBERSHIP LINKS -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MembershipLinks = ({ state, setState }) => {
    const history = useHistory();

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20, display: 'flex' }}>
                <div onClick={() => setState({ ...state, openUpdate: true, openLinks: false })} className="subscription-links">
                    Modifier l'adhésion
                </div>
                <div
                    onClick={() => history.push('/management/memberships/' + state.billId)}
                    className="subscription-links"
                    style={{ marginLeft: 24 }}
                >
                    Accéder au bon de commande
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={() => setState({ ...state, openLinks: false })} variant="contained">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default MembershipsRouter;
